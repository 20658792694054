import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import Contentful from "./app/Contentful";
import Home from "./pages/home";

import NotFound from "./pages/not-found";


ReactGA.initialize("UA-142979763-2");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  state = {
    isLoaded: false,
    showModal: false,
    showAlert: true,
    showMobileMenu: false,
    showCookieNotice: false,
    alertData: [],
  };

  toggleModal = (val) => {
    if (val === true) {
      this.setState({
        showModal: val,
        showMobileMenu: false,
      });
    } else {
      this.setState({
        showModal: val,
      });
    }
  };

  toggleAlert = (val) => {
    this.setState({
      showAlert: val,
    });
  };

  toggleMobileMenu = (val) => {
    this.setState({
      showMobileMenu: val,
    });
  };

  hideCookieNotice = () => {
    this.setState({
      showCookieNotice: false,
    });
  };

  componentDidMount() {
    this.fetchEntry().then(this.setEntry);
  }

  fetchEntry = () => Contentful.getEntry("5rImZ3TRSGLup1jL2gOWe3");

  setEntry = (response) => {
    this.setState({
      isLoaded: true,
      alertData: response,
    });
  };

  render() {
    const { isLoaded } = this.state;

    return (
      isLoaded && (
        <React.Fragment>

          <main className="container">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/not-found" component={NotFound} />
            </Switch>
          </main>


        </React.Fragment>
      )
    );
  }
}

export default App;
