import React from "react";
import SEO from "../app/SEO";
import Holding from "../components/holding";

const Home = () => {
  return (
    <React.Fragment>
      <SEO path={""} content={""} />

      <Holding />
    </React.Fragment>
  );
};

export default Home;
