import React, { Component } from 'react';

class NotFound extends Component {

    render() {
        return (
            <div>Page not found</div>
        );
    }

}

export default NotFound;
