import React from 'react'
import { Flex, Box,  Text } from 'rebass'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
    from {opacity: 0; transform: translateY(10px)}
    to {opacity: 1; transform: translateY(0px)}
`

const Logo = styled(Flex)`
  opacity: 0;
  transform: translateY(10px);
  animation: ${fadeIn} 0.3s ease-in-out ${props => props.delay}s forwards;
`

const Wrapper = styled(Flex)`
  opacity: 0;
  transform: translateY(10px);
  animation: ${fadeIn} 0.4s ease-in-out ${props => props.delay}s forwards;
`

const Button = styled(Box)`
  opacity: 0;
  transform: translateY(0px);
  animation: ${fadeIn} 0.4s ease-in-out ${props => props.delay}s forwards;
`

const Loader = ({ title = '', delayLogo = 0, delayTitle = 0, delayButton = 0, anchor = false }) => (
  <>
    <Logo delay={delayLogo} mb="20px">
    <div className="text-block">
      <h1 className="logo">Flor</h1>
    </div>
    </Logo>
    <Wrapper delay={delayTitle} px="20px" sx={{ textAlign: 'center' }}>
      <Text fontSize="18px" maxWidth='680px'>
        {title}
      </Text>
    </Wrapper>
    <Button mt="80px" delay={delayButton}>
        <a href="https://lyleslondon.com"><div class="btn">Visit Lyle's</div></a>
    </Button>
  </>
)

const Holding = () => {
  return (
    <Flex
      bg="#556869"
      height='100vh'
      width='100vw'
      alignItems='center'
      justifyContent='center'
      flexDirection='column'
    >
      <Loader
        title='Flor has paused production. For our sister restaurant, visit lyleslondon.com'
        delayLogo={2} delayTitle={3} delayButton={4} anchor
      />
    </Flex>
  )
}

export default Holding
