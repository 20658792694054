module.exports = {
  siteTitle: "Flor",
  siteDescription:
    "Wine Bar, Bakery, and Restaurant in London's Borough Market",
  siteDescriptionLength: 150,
  siteUrl: "https://florlondon.com", // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  siteKeywords: "",
  author: "", // Organization name used for SEO schema
  userTwitter: "", // Change for Twitter Cards
  shortTitle: "", // Used for App manifest e.g. Mobile Home Screen
  shareImage: "/assets/images/meta-img.jpg", // Open Graph Default Share Image. 1200x1200 is recommended
  siteLogo: "/assets/images/meta-img.jpg", // Logo used for SEO, RSS, and App manifest
  imageHeight: 800, // Default image sharing size
  imageWidth: 643, // Default image sharing size
  redirectUri: "http://localhost:3000/callback",
  contenfulSpaceId: "2o8bdk29mzrn",
  contenfulAccessToken: "1esCVXTTwov10zmnXUdt9NayWf-Q-CzM8k_l7oRMmOw",
};
